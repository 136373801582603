<template>
  <section class="construction-contract">
    <section class="hero">
      <div class="hero-container">
        <h1>Les avantages du Contrat de construction de Maison Individuelle (CCMI)</h1>
      </div>
    </section>
    <div class="hero-img">
      <img
        src="@/assets/img/pages/construction-contract-1.jpg"
        alt="Les avantages du Contrat de construction de Maison Individuelle (CCMI)"
      />
    </div>
    <article class="grid-container article">
      <div class="article-title fit-content">
        <h3>
          En France, la majorité des maisons sont construites sous ce régime juridique très
          réglementé. Les garanties qu’il offre rassurent en effet les banques, autant qu’elles
          protègent l’acquéreur. En quoi ?
        </h3>
      </div>
      <div class="article-card">
        <card-type-img>
          <template v-slot:content>
            <p>
              Créé en 1990, le contrat de construction de maison individuelle (CCMI) est devenu, au
              fil des décennies, un levier incontournable du marché du logement neuf. A preuve,
              entre 60 et 80% des opérations s’effectuent aujourd’hui sous cette forme. Pourquoi les
              acquéreurs le plébiscitent-ils ?
            </p>
            <p>
              D’abord parce qu’il offre un cadre strict et intangible qui les préserve des mauvaises
              surprises liées aux impondérables d’un chantier (respect des délais, surcoûts
              éventuels): contrairement à d’autres types de contrats, il fixe un prix définitif
              convenu dès le départ entre les parties et établit un échéancier de paiement très
              précis.
            </p>
            <p>
              Deuxième raison, qui intéresse surtout les primo-accédants : le recours à ce régime
              facilite généralement leur accès au prêt immobilier. Les modalités juridiques du CCMI
              sécurisent en effet les banques qui débloquent plus volontiers un crédit pour financer
              un projet régi par des règles transparentes fixées par la loi.
            </p>
            <p>
              Pour bien comprendre, reprenons le dispositif par le menu en le confrontant à trois
              questions simples.
            </p>
          </template>
          <template v-slot:img>
            <img
              src="@/assets/img/pages/construction-contract-2.jpg"
              alt="Taux zéro : limiter l’application du PTZ aux zones dites « en tension »"
            />
          </template>
        </card-type-img>
      </div>
      <section class="article-content fit-content">
        <div class="article-content-section">
          <h2>A qui s’adresse-t-il ?</h2>
          <div class="article-content-img">
            <img
              src="@/assets/img/pages/construction-contract-3.jpg"
              alt="A qui s’adresse-t-il ?"
            />
          </div>
          <div class="article-content-text">
            <p>
              A tout particulier qui souhaite faire bâtir un logement individuel, à condition
              toutefois qu’il soit propriétaire du terrain à bâtir. Le CCMI devient une procédure
              obligatoire dès lors que l’opération est confiée à <router-link :to="{ name: 'project-house-construction' }">un constructeur de maison</router-link>, lequel
              prend, à ce titre, soit l’engagement de prendre en charge et de coordonner l’ensemble
              des travaux, soit de limiter son intervention à la réalisation du gros œuvre
              (fondations), de la mise hors d’eau (étanchéité, couverture) et hors d’air (portes, et
              fenêtres). Dans ce dernier cas, le contrat s’exécute sans fourniture préalable <router-link :to="{ name: 'smart-house-plan' }">du plan de la maison de plain-pied ou à étage</router-link>. Dans le premier en revanche, plus fréquent que
              le précédent, ces documents sont à remettre au maître d’ouvrage, autrement dit le
              futur occupant et propriétaire de l’habitat. Pour rappel, un contrat de maitrise
              d’œuvre signé avec un architecte n’est pas aussi réglementé qu’un CCMI : les clauses
              qui y figurent ont été « librement » négociées par chacune des parties.
            </p>
          </div>
        </div>
        <div class="article-content-section">
          <h2>Que contient-il ?</h2>
          <div class="mobile-card">
            <p>
              Outre les plans (si le constructeur s’occupe du chantier du début à la fin), plusieurs
              pièces et informations sont à annexer au contrat : une copie des différentes
              autorisations administratives relatives à l’opération (dont une copie du permis de
              construire), le titre de propriété, les données techniques du logement à bâtir, son
              prix, la date d’ouverture des travaux et le délai de livraison.
            </p>
          </div>
        </div>
        <div class="article-content-section">
          <h2>Que garantit-il ?</h2>
          <div class="article-content-img">
            <img
              src="@/assets/img/pages/construction-contract-4.jpg"
              alt="Que garantit le contrat de construction ?"
            />
          </div>
          <div class="article-content-text">
            <p>
              Conformément à l’<a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000039344324/">article R231-7 du Code de la Construction et de l’Habitation</a>, le CCMI
              (avec fourniture de plan) fixe une modalité de paiement progressive fondée sur le
              respect d’un calendrier strict, celui de l’avancée des travaux. Chaque appel de fonds
              correspond à une étape décisive du chantier : leur montant est réglementé par la loi
              et s’élève, au démarrage, à 15% maximum du prix total de l’opération, 25 % dès
              l’achèvement des fondations, 40 % lorsque les murs extérieurs sont bâtis, 60 % à la
              mise hors d’eau (pose du toit), 75 % après l’aménagement des cloisons intérieures et
              l’installation des portes et fenêtres, 95 % à l’issue des dernières finitions de
              revêtement, menuiserie, plomberie, chauffage et autres équipements (en général, le
              reliquat de 5% est à régler à la remise des clés).
            </p>
            <p>
              Ce type d’échéancier, établi sur des bases contractuelles, fournit un cadre clair aux
              deux parties et offre de la souplesse à l’acquéreur, et de la visibilité au
              constructeur. Le CCMI comprend également toute une série de clauses suspensives qui
              permettent au maître d’ouvrage (acheteur) de se désengager si certaines conditions
              préalables, comme l’obtention d’un prêt immobilier, la validation d’un permis de
              construire ou la souscription de garanties d’assurance obligatoires (infra), ne sont
              pas remplies.
            </p>
          </div>
        </div>
        <div class="article-content-cards">
          <h3>
            Enfin, le contrat couvre un certain nombre de risques à travers des assurances
            obligatoires qui proposent :
          </h3>
          <div>
            <div class="card-content">
              <moneydark />
              <h3>Une garantie de livraison :</h3>
              <p>
                En vertu de cette disposition, inscrite dans la loi du 19 décembre 1990, une banque
                ou une compagnie d’assurance s’engagent par une caution solidaire à financer le
                chantier en cas de manquement avéré de la part du constructeur (travaux inachevés ou
                mal exécutés, dépassement du prix convenu). Elle est complétée par une garantie de
                remboursement « si le contrat prévoit des paiements avant la date d’ouverture » des
                opérations.
              </p>
            </div>
            <div class="card-content">
              <housedark />
              <h3>Une garantie de parfait achèvement :</h3>
              <p>
                Valable un an après la livraison du logement, elle oblige le constructeur à
                effectuer, à ses frais, la réparation des anomalies constatées au cours de cette
                période.
              </p>
            </div>
            <div class="card-content">
              <collapsedark />
              <h3>Une garantie décennale :</h3>
              <p>
                Elle porte sur les dommages qui, dans les dix premières années, compromettent la
                solidité du bâti ou rendent la maison inhabitable (risque d’effondrement, fissures,
                mauvaise étanchéité…).
              </p>
            </div>
            <div class="card-content">
              <constructiondark />
              <h3>Une garantie de bon fonctionnement :</h3>
              <p>
                Valable deux ans après l’achèvement des travaux, elle impose des interventions sur «
                tous les éléments d’équipement dissociables et démontables à la construction ».
                Cette clause concerne, par exemple, les défaillances, malfaçons et autres vices
                observés au niveau de la chaudière, du ballon électrique, des radiateurs, des portes
                et fenêtres….
              </p>
            </div>
            <div class="card-content">
              <walletdark />
              <h3>La garantie dommage-ouvrage :</h3>
              <p>
                souscrite obligatoirement par le particulier-acquéreur (mais le constructeur peut
                l’inclure directement dans le CCMI), elle prend en charge les dépenses à engager au
                titre des éventuelles réparations couvertes par la garantie décennale précédemment
                citée. Il s’agit donc d’une « avance » ou d’un « préfinancement » versé par
                l’assureur, le temps qu’une décision de justice statue sur la responsabilité de
                l’une ou l’autre des deux parties.
              </p>
            </div>
          </div>
        </div>
      </section>
    </article>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';
import walletdark from '@/assets/img/wallet-dark.svg?inline';
import moneydark from '@/assets/img/money-dark.svg?inline';
import housedark from '@/assets/img/house-dark.svg?inline';
import collapsedark from '@/assets/img/collapse-dark.svg?inline';
import constructiondark from '@/assets/img/construction-dark.svg?inline';

export default {
  name: 'construction-contract',
  components: {
    CardTypeImg,
    walletdark,
    moneydark,
    housedark,
    collapsedark,
    constructiondark,
  },
};
</script>

<style lang="sass">
.construction-contract
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero
    background-color: darken($primary, 5%)
    height: 35rem
    display: flex
    flex-direction: column
    align-items: center
    &-container
      margin-top: 8rem
      width: 60rem
      h1
        color: $white
        text-align: center
    &-img
      display: flex
      flex-direction: row
      justify-content: center
      img
        border-radius: $global-border-radius
        margin-top: -15rem
  .article
    &-title
      display: flex
      justify-content: center
      margin: 4.5rem auto 0 auto
      h3
        text-align: center
    &-card
      margin: 4.5rem auto 0 auto
      p:not(:last-child)
        margin-bottom: 1rem
      a
        font-weight: normal !important
    &-content
      margin: 4.5rem auto 0 auto
      &-section
        &:not(:last-child)
          margin-bottom: 4.5rem
        h2
          text-align: center
          color: $primary
          margin-bottom: 3rem
      &-text
        p:not(:last-child)
          margin-bottom: 1rem
        p
          a
            color: $primary
            text-decoration: none
            font-size: 16px
      &-img
        margin-bottom: 2rem
        img
          width: 100%
          object-fit: cover
          border-radius: $global-border-radius
      &-cards
        > h3
          color: $body-color
          font-weight: 500
          font-size: $font-lg
          line-height: 32px
          text-align: center
        > div
          display: flex
          flex-direction: column
          flex-wrap: wrap
          column-gap: 1.5rem
          max-height: 1050px
          margin-top: 3rem
          .card-content
            @include card-content
            max-width: 450px
            &:not(:last-child)
              margin-bottom: 1.5rem
  .fit-content
    width: 90%
    max-width: 950px
  @media (max-width: 768px)
    .mobile-card
      background-color: $white
      display: flex
      flex-direction: column
      box-shadow: $drop-shadow
      border-radius: $global-border-radius
      padding: 1.75rem 1rem
    .hero
      height: 25rem
      @media (max-width: 350px)
        height: 28rem
      &-container
        margin-top: 6rem
        width: 90%
      &-img
        img
          object-fit: cover
          width: 90%
          height: 250px
          margin-top: -8rem
    .article
      &-title
        margin: 2.5rem auto 0 auto
      &-card
        margin: 2.5rem auto 0 auto
      &-content
        &-section
          &:not(:last-child)
            margin-bottom: 3.25rem
          h2
            margin-bottom: 2rem
        &-img
          margin-bottom: 1.5rem
          img
            height: 220px
        &-cards
          > div
            flex-wrap: nowrap
            max-height: unset
</style>
